import axios, { AxiosInstance, AxiosResponse } from "axios";
import AuthService from "./AuthService";
import { ErrorResponse, User } from "../types";
import { ApiResponse } from "./ApiResponse";
import _ from "lodash";
import { createDebounce } from "../utils/Tools";
import { v4 as uuidv4 } from 'uuid';

export type chargeType = 'user' | 'pro' | 'elite' | 'crypto';

class UserService {
  private static instance: UserService; // Singleton instance
  private api: AxiosInstance;
  private userCache: User | null = null;
  private debouncedGetUser: () => Promise<ApiResponse<User, ErrorResponse>>;
  private debouncedCreateUser: (pushToken?: string) => Promise<ApiResponse<User, ErrorResponse>>;

  private constructor() { // Private constructor for singleton
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_SHROUDED_BASE_URL}/v1/user`,
    });

    this.api.interceptors.request.use((config) => {
      const token = AuthService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['x-trace-id'] = uuidv4();  

      return config;
    });

    this.loadCacheFromLocalStorage();

    // Initialize debounced methods using the utility function
    this.debouncedGetUser = createDebounce(
      () => this._getUser(),
      300
    );

    this.debouncedCreateUser = createDebounce(
      (pushToken?: string) => this._createUser(pushToken),
      300
    );
  }

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  async getUser(): Promise<ApiResponse<User, ErrorResponse>> {
    return this.debouncedGetUser();
  }

  private async _getUser(): Promise<ApiResponse<User, ErrorResponse>> {
    const cacheEntry = this.userCache; // In-memory cache
    const headers: Record<string, string> = {};
    if (cacheEntry?.version) {
      headers["etag"] = String(cacheEntry.version);
    } else {
      headers["etag"] = "0";
    }

    try {
      const response = await this.api.get<{ user: User }>("", { headers });
      const etag = response.headers["etag"];

      if (etag) {
        this.cacheUser(response.data.user, etag); // Cache user and ETag
      } else {
        this.clearCache(); // Clear cache if no ETag
      }

      return ApiResponse.success<User, ErrorResponse>(response.data.user);
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 304) {
        if (cacheEntry) {
          return ApiResponse.success<User, ErrorResponse>(cacheEntry);
        }
      } else {
        this.clearCache();
      }

      return ApiResponse.failure<User, ErrorResponse>(error);
    }
  }

  updateCache(updatedUser: User) {
    this.cacheUser(updatedUser, null);
  }

  clearCache() {
    this.userCache = null;
    localStorage.removeItem("cachedUser");
  }

  private cacheUser(user: User, etag: string | null) {
    this.userCache = user;
    localStorage.setItem("cachedUser", JSON.stringify(user));
  }

  private loadCacheFromLocalStorage() {
    const cachedUser = localStorage.getItem("cachedUser");
    if (cachedUser) {
      this.userCache = JSON.parse(cachedUser) as User;
    }
  }

  async userExists() {
    try {
      const response = await this.api.get("/exists");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createUser(pushToken?: string): Promise<ApiResponse<User, ErrorResponse>> {
    return this.debouncedCreateUser(pushToken);
  }

  private async _createUser(pushToken?: string): Promise<ApiResponse<User, ErrorResponse>> {
    try {
      const response = await this.api.post("", { pushToken });
      return ApiResponse.success<User, ErrorResponse>(response.data.user);
    } catch (error: any) {
      return ApiResponse.failure<User, ErrorResponse>(error);
    }
  }

  async createCharge(chargeType: chargeType): Promise<{
    hosted_url: string;
    id: string;
    metadata: string;
  }> {
    try {
      const currentUrl = new URL(window.location.href);
  
      const redirectUrl = new URL(currentUrl.href);
      redirectUrl.searchParams.set("status", "success");
  
      const cancelUrl = new URL(currentUrl.href);
      cancelUrl.searchParams.set("status", "fail");
  
      const response = await this.api.post("/createCharge", {
        redirectUrl: redirectUrl.toString(),
        cancelUrl: cancelUrl.toString(),
        chargeType,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteUser() {
    try {
      const response = await this.api.delete("/");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getSubscriptionDetails() {
    try {
      const response = await this.api.get("/subscriptionDetails");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async resendVerificationEmail() {
    try {
      const response = await this.api.post("/resendVerificationEmail");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updatePushToken(pushToken: string) {
    try {
      const response = await this.api.post("/updatePushToken", { pushToken });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default UserService.getInstance();
