import React, { useState } from "react";
import { TextField, Typography, Box, Card, CardContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AuthService from "../services/AuthService";

const ForgotPasswordScreen: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async () => {
    setError("");
    setMessage("");
    setLoading(true);

    try {
      await AuthService.forgotPassword(email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (err: any) {
      setError(err.message || "Failed to send password reset email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="#f5f5f5">
      <Card elevation={3} style={{ width: "400px", padding: "20px" }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Forgot Password
          </Typography>

          {message && (
            <Typography color="primary" align="center" gutterBottom>
              {message}
            </Typography>
          )}

          {error && (
            <Typography color="error" align="center" gutterBottom>
              {error}
            </Typography>
          )}

          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />

          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "20px" }}
            loading={loading}
            onClick={handleForgotPassword}
            disabled={!email || loading}
          >
            Send Reset Link
          </LoadingButton>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgotPasswordScreen;
