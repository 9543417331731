export class ApiResponse<TSuccess, TError> {
    private data: TSuccess | null;
    private error: TError | null;
  
    constructor(data: TSuccess | null, error: TError | null) {
      this.data = data;
      this.error = error;
    }
  
    static success<TSuccess, TError>(data: TSuccess): ApiResponse<TSuccess, TError> {
      return new ApiResponse<TSuccess, TError>(data, null);
    }
  
    static failure<TSuccess, TError>(error: TError): ApiResponse<TSuccess, TError> {
      return new ApiResponse<TSuccess, TError>(null, error);
    }
  
    isSuccessful(): boolean {
      return this.data !== null;
    }
  
    getData(): TSuccess | null {
      return this.data;
    }
  
    getError(): TError | null {
      return this.error;
    }
  }
  