import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { Star, Delete, MailOutline, Add } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import UserService from "../services/UserService";
import { EmailAddressVerification } from "../types";
import EmailService from "../services/EmailService";

const ToEmailsScreen: React.FC = () => {
  const [emails, setEmails] = useState<EmailAddressVerification[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedEmail, setSelectedEmail] = useState<EmailAddressVerification | null>(null);
  const [isVerificationLoading, setIsVerificationLoading] = useState<boolean>(false);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>("");
  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await UserService.getUser();
        const user = response.getData();
        if (!user) {
          return;
        }
        setEmails(user.emailVerifications); // Assume the response is an array of EmailAddressVerification
      } catch (error) {
        console.error("Error fetching emails:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEmails();
  }, []);

  const handleDeleteEmail = async (email: string) => {
    try {
      setEmails((prev) => prev.filter((e) => e.email !== email));
    } catch (error) {
      console.error("Error deleting email:", error);
    }
  };

  const handleSendVerification = async () => {
    if (!selectedEmail) return;
    setIsVerificationLoading(true);
    try {
      alert(`Verification email sent to ${selectedEmail.email}`);
      await EmailService.sendVerifyEmail(selectedEmail.email);
    } catch (error) {
      console.error("Error sending verification email:", error);
      alert("Failed to send verification email.");
    } finally {
      setIsVerificationLoading(false);
      setSelectedEmail(null);
    }
  };

  const handleAddEmail = async () => {
    if (newEmail.trim() === "") {
      alert("Please enter a valid email address.");
      return;
    }
    setIsAddLoading(true);
    try {
    
        await EmailService.createEmail(newEmail);
        setNewEmail("");
        setIsAddDialogOpen(false);
    } catch (error) {
        console.error("Error adding email:", error);
    } finally {
        setIsAddLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4 }}>
      <Typography variant="h6" gutterBottom>
        To Emails
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setIsAddDialogOpen(true)}
        sx={{ mt: 2 }}
      >
        Add Email
      </Button>
      {loading ? (
        <CircularProgress />
      ) : (
        <List>
          {emails.map((emailObj, index) => (
            <ListItem
              key={emailObj.email}
              sx={{ borderBottom: "1px solid #ddd" }}
              secondaryAction={
                <>
                  {!emailObj.isEmailVerified && (
                    <IconButton
                      edge="end"
                      color="primary"
                      aria-label="verify-email"
                      onClick={() => setSelectedEmail(emailObj)}
                    >
                      <MailOutline />
                    </IconButton>
                  )}
                  {index !== 0 && (
                    <IconButton
                      edge="end"
                      color="error"
                      aria-label="delete"
                      onClick={() => handleDeleteEmail(emailObj.email)}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </>
              }
            >
              <ListItemIcon>
                {index === 0 ? <Star color="primary" /> : null}
              </ListItemIcon>
              <ListItemText
                primary={emailObj.email}
                primaryTypographyProps={{
                  fontWeight: index === 0 ? "bold" : "normal",
                }}
                secondary={
                  !emailObj.isEmailVerified ? "Not Verified" : undefined
                }
              />
            </ListItem>
          ))}
        </List>
      )}

      {/* Add Email Button */}

      {/* Verification Modal */}
      <Dialog
        open={Boolean(selectedEmail)}
        onClose={() => setSelectedEmail(null)}
        aria-labelledby="verify-email-dialog"
      >
        <DialogTitle id="verify-email-dialog">
          Verify Email
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Send a verification email to ${selectedEmail?.email}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedEmail(null)} color="primary">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSendVerification}
            loading={isVerificationLoading}
            color="primary"
            variant="contained"
          >
            Send Verification
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Add Email Modal */}
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        aria-labelledby="add-email-dialog"
      >
        <DialogTitle id="add-email-dialog">
          Add Email
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <LoadingButton
            onClick={handleAddEmail}
            loading={isAddLoading}
            color="primary"
            variant="contained"
          >
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ToEmailsScreen;
