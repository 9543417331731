import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  Divider,
  LinearProgress,
  Alert,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import UserService from "../services/UserService";

const AgreeScreen: React.FC = () => {
  const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [allAgreed, setAllAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paywall, setPaywall] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "success") {
      setPaywall(false);
      navigate("/dashboard");
    } else if (status === "fail") {
      setPaywall(false);
      setShowError(true);
    }
  }, [location.search, navigate]);

  const handleLinkClick = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    setter(true);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked && agreedToPrivacy && agreedToTerms) {
      setAllAgreed(true);
    } else {
      setAllAgreed(false);
    }
  };

  const handleNext = async () => {
    if (!allAgreed || loading) return;

    setLoading(true);
    try {
      await UserService.createUser();
      setPaywall(true);
    } catch (error) {
      console.error("Failed to create user:", error);
    } finally {
      setLoading(false);
    }
  };

  const onPaywallClose = () => setPaywall(false);

  return (
    <Box sx={{ padding: 4, maxWidth: 600, mx: "auto", textAlign: "center" }}>
      {/* Title Section */}
      <Typography
        variant="h4"
        sx={{ marginBottom: 2, color: "primary.main", fontWeight: "bold" }}
      >
        Agree to Continue
      </Typography>

      <Typography
        variant="body1"
        sx={{ marginBottom: 3, color: "text.secondary" }}
      >
        Please read and agree to our Privacy Policy and Terms of Use before proceeding.
      </Typography>

      {/* Error Message for Failed Subscription */}
      {showError && (
        <Alert severity="error" sx={{ marginBottom: 3 }}>
          You need to subscribe to move forward.
        </Alert>
      )}

      {/* Progress Indicator */}
      <LinearProgress
        variant="determinate"
        value={(Number(agreedToPrivacy) + Number(agreedToTerms)) * 50}
        sx={{ marginBottom: 3 }}
      />

      {/* Links Section */}
      <Box sx={{ textAlign: "left", marginBottom: 3 }}>
        <Typography variant="body2">
          <Link
            href="https://shrouded.io/v1/privacypolicy.html"
            target="_blank"
            rel="noopener"
            onClick={() => handleLinkClick(setAgreedToPrivacy)}
            sx={{ color: agreedToPrivacy ? "success.main" : "primary.main" }}
          >
            {agreedToPrivacy ? "✔ Privacy Policy (Read)" : "Privacy Policy"}
          </Link>
        </Typography>
        <Typography variant="body2">
          <Link
            href="https://shrouded.io/v1/termsofuse.html"
            target="_blank"
            rel="noopener"
            onClick={() => handleLinkClick(setAgreedToTerms)}
            sx={{ color: agreedToTerms ? "success.main" : "primary.main" }}
          >
            {agreedToTerms ? "✔ Terms of Use (Read)" : "Terms of Use"}
          </Link>
        </Typography>
      </Box>

      {/* Agreement Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            onChange={handleCheckboxChange}
            color="primary"
            disabled={!agreedToPrivacy || !agreedToTerms}
          />
        }
        label="I agree to the Privacy Policy and Terms of Use"
        sx={{ marginBottom: 3 }}
      />

      {/* Next Button */}
      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleNext}
        loading={loading}
        disabled={!allAgreed}
        fullWidth
      >
        Next
      </LoadingButton>

      {/* Subscription Modal */}
    </Box>
  );
};

export default AgreeScreen;
