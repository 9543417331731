import axios, { AxiosInstance } from "axios";
import AuthService from "./AuthService";
import { ApiResponse } from "./ApiResponse";
import { ErrorResponse } from "../types";

class EmailProxyService {


  private static instance: EmailProxyService; // Singleton instance
  private api: AxiosInstance;

  private constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_SHROUDED_BASE_URL}/v1/email-proxy`,
    });

    this.api.interceptors.request.use((config) => {
      const token = AuthService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  public static getInstance(): EmailProxyService {
    if (!EmailProxyService.instance) {
        EmailProxyService.instance = new EmailProxyService();
    }
    return EmailProxyService.instance;
  }

  async deleteShroud(ids: string[]) {
    try {
        await this.api.delete("/delete", {data: {ids}});
      } catch (error) {
        throw this.handleError(error);
      }
  }

  async getDomains(username: string): Promise<ApiResponse<{domains: string[]}, ErrorResponse>> {
    try {
      const response = await this.api.get("/domains", {params: {username}});
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Get all verified emails
   */
  async toggle(ids: string[], enable: boolean): Promise<ApiResponse<any[], ErrorResponse>> {
    try {
      const response = await this.api.post("/toggle", {ids, enable});
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

    /**
   * Get all verified emails
   */
    async testEmail(routeId: string): Promise<ApiResponse<any[], ErrorResponse>> {
        try {
          const response = await this.api.post("/test-email", {routeId});
          return ApiResponse.success(response.data);
        } catch (error) {
          throw this.handleError(error);
        }
    }

    async addEmailProxy(fullEmail: string, selected: string[]) {
        try {
            const response = await this.api.post("/add", {from: fullEmail, to: selected });
            return ApiResponse.success(response.data);
          } catch (error) {
            throw this.handleError(error);
        }
    }
    
  /**
   * Error handler for API requests
   */
  private handleError(error: any): Error {
    if (axios.isAxiosError(error)) {
      console.error("API Error:", error.response?.data || error.message);
      return error;
    } else {
      console.error("Unexpected Error:", error);
      return new Error("An unexpected error occurred");
    }
  }
}

export default EmailProxyService.getInstance();
