import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Register the service worker for offline capabilities
serviceWorkerRegistration.register({
  onSuccess: () => {
    console.log('Service Worker registered successfully!');
  },
  onUpdate: (registration) => {
    alert('New version available! Please refresh the page.');
    // Optionally force reload:
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
    window.location.reload();
  },
});
