import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Typography,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  Radio,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import UserService from "../services/UserService";
import { EmailProxy, User } from "../types";
import { useNavigate } from "react-router-dom";
import SubscriptionModal from "../components/SubscriptionModal";
import AuthService from "../services/AuthService";
import EmailProxyService from "../services/EmailProxyService";

const Dash: React.FC = () => {
  const [shrouds, setShrouds] = useState<EmailProxy[]>([]);
  const [user, setUser] = useState<User>();
  const [selected, setSelected] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [warn, setWarn] = useState<string | null>(null);
  const [info, setInfo] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [paywall, setPaywall] = useState<boolean>(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchShrouds = async () => {
    try {
      setLoading(true);
      let response = await UserService.getUser();
      let user = null;
      if (!response.isSuccessful()) {
        navigate("/agree");
        return;
      } else {
        user = response.getData(); 
      }

      if (!user) {
        throw new Error("Auth issues")
      }

      setUser(user);
      setShrouds(user.emailProxy);
      if (user.subscriptionStatus === "Pending") {
        setWarn("Your account is pending");
      }
    } catch (err) {
      setError("Failed to fetch shrouds. Please try again." + JSON.stringify(err));
      await AuthService.logout();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShrouds();
  }, []);


  const toggleShroud = async (routeId: string, enabled: boolean) => {
    try {
      const updatedShrouds = shrouds.map((shroud) =>
        shroud.routeId === routeId ? { ...shroud, enabled } : shroud
      );
      setShrouds(updatedShrouds);
      await EmailProxyService.toggle([routeId], enabled);
    } catch (err) {
      setError("Failed to update shroud status.");
      const revertedShrouds = shrouds.map((shroud) =>
        shroud.routeId === routeId ? { ...shroud, enabled: !enabled } : shroud
      );
      setShrouds(revertedShrouds);
    }
  };

  const testShroud = async () => {
    if (!selected) return;
    try {
      await EmailProxyService.testEmail(selected);
      setInfo("Test emails sent for selected shroud!");
      setTimeout(() => setInfo(null), 5000);
    } catch (error: any) {
      if (error.status === 429) {
        setInfo("Only 1 test per hour");
        setTimeout(() => setInfo(null), 5000);
      } else {
        setError("Failed to test selected shroud.");
      }
    }
  };

  const deleteShroud = async () => {
    if (!selected) return;
    try {
      await EmailProxyService.deleteShroud([selected]);
      setShrouds(shrouds.filter((shroud) => shroud.routeId !== selected));
      setSelected(null);
    } catch {
      setError("Failed to delete shroud.");
    }
  };

  const toggleSelection = (routeId: string) => {
    setSelected(selected === routeId ? null : routeId);
  };

  const onPaywallClose = () => setPaywall(false);

  return (
    <Box sx={{ maxWidth: 1000, mx: "auto", mt: 4, px: 2 }}>
      <Typography variant="h5" gutterBottom>
        Shrouds
      </Typography>
      {user && user.subscriptionStatus === "Disabled" && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Your account is disabled. Reedem a week free,{" "}
            <Link
              component="button"
              variant="body2"
              onClick={() => setPaywall(true)}
            >
              subscribe now
            </Link>
            .
          </Alert>
        )}

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {warn && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          {warn}{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => window.open(user?.metadata?.coinbaseUrl, "_blank")}
          >
            See Transaction
          </Link>
        </Alert>
      )}
      {info && <Alert severity="info" sx={{ mb: 2 }}>{info}</Alert>}

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        {user && user.subscriptionStatus === "Active" && (
          <Button variant="contained" color="primary" onClick={() => navigate("/add-shroud")}>
            Add Shroud
          </Button>
        )}

        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={testShroud}
            disabled={!selected}
          >
            Test
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={deleteShroud}
            disabled={!selected}
          >
            Delete
          </Button>
        </Box>
      </Box>

      {user && 
        <SubscriptionModal user={user} isVisible={paywall} onClose={onPaywallClose} />
      }

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Select</TableCell>
              <TableCell>{isMobile ? "From/To" : "From"}</TableCell>
              {!isMobile && <TableCell>To</TableCell>}
              <TableCell>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={isMobile ? 4 : 6}>Loading...</TableCell>
              </TableRow>
            ) : shrouds.length > 0 ? (
              shrouds.map((shroud) => (
                <TableRow key={shroud.routeId}>
                  <TableCell padding="checkbox">
                    <Radio
                      checked={selected === shroud.routeId}
                      onChange={() => toggleSelection(shroud.routeId)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    {isMobile ? (
                      <>
                        <strong>From:</strong> {shroud.from}
                        <br />
                        <strong>To:</strong> {shroud.to}
                      </>
                    ) : (
                      shroud.from
                    )}
                  </TableCell>
                  {!isMobile && <TableCell>{shroud.to}</TableCell>}
                  <TableCell>
                    <Switch
                      checked={shroud.enabled}
                      onChange={() => toggleShroud(shroud.routeId, !shroud.enabled)}
                      color="primary"
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={isMobile ? 4 : 6}>{user && user.subscriptionStatus === "Disabled" ? "Subscribe to use Shrouded!" : "Create your first Shroud!"}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Dash;
