import EventEmitter from "events";

export default class CrossTabEventEmitter {
    private static instance: CrossTabEventEmitter | null = null;
    private eventEmitter = new EventEmitter();
    private broadcastChannel?: BroadcastChannel;
  
    // Singleton pattern
    private constructor() {
      if (this.isBroadcastChannelSupported()) {
        this.broadcastChannel = new BroadcastChannel("cross_tab_channel");
        this.broadcastChannel.onmessage = (message) => {
          const { event, payload } = message.data;
          this.eventEmitter.emit(event, payload);
        };
      } else {
        window.addEventListener("storage", (e) => {
          if (e.key === "cross_tab_event" && e.newValue) {
            const { event, payload } = JSON.parse(e.newValue);
            this.eventEmitter.emit(event, payload);
          }
        });
      }
    }
  
    public static getInstance(): CrossTabEventEmitter {
      if (!CrossTabEventEmitter.instance) {
        CrossTabEventEmitter.instance = new CrossTabEventEmitter();
      }
      return CrossTabEventEmitter.instance;
    }
  
    private isBroadcastChannelSupported(): boolean {
      return typeof BroadcastChannel !== "undefined";
    }
  
    public emit(event: string, payload?: any): void {
      this.eventEmitter.emit(event, payload);
  
      // Cross-tab broadcast
      if (this.broadcastChannel) {
        this.broadcastChannel.postMessage({ event, payload });
      } else {
        localStorage.setItem(
          "cross_tab_event",
          JSON.stringify({ event, payload, timestamp: Date.now() })
        );
      }
    }
  
    public on(event: string, callback: (payload?: any) => void): void {
      this.eventEmitter.on(event, callback);
    }
  
    public off(event: string, callback: (payload?: any) => void): void {
      this.eventEmitter.off(event, callback);
    }
  
    public removeAllListeners(event: string): void {
      this.eventEmitter.removeAllListeners(event);
    }
  }
  