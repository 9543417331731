import React, { useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import UserService, { chargeType } from "../services/UserService";
import { User } from "../types";

const SubscriptionDialog: React.FC<{ isVisible: boolean; onClose: () => void, user : User }> = ({
  isVisible,
  onClose,
  user
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedPlan, setSelectedPlan] = useState<chargeType>("user");
  const [isLoading, setIsLoading] = useState(false);

  const planDetails: Record<string, string[]> = {
    user: ["Up to 250 Emails/Month and 8 shrouds.", "$2.99 per Month"],
    pro: ["Up to 500 Emails/Month and 16 shrouds.", "$5.99 per Month"],
    elite: ["Up to 1000 Emails/Month and 32 shrouds.", "$9.99 per Month"],
    crypto: ["Up to 1000 Emails/Month and 32 shrouds.", "One time payment $122.95 for a Year"],
  };

  const handleSubscription = async () => {
    setIsLoading(true);

    if(true) {
      alert("Subscription disabled on web. Please use mobile app for now.")
      return;
    }

    try {
      const response = await UserService.createCharge(selectedPlan);
      window.location.href = response.hosted_url;
    } catch (error) {
      console.error("Subscription error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={isVisible}
      onClose={onClose}
      aria-labelledby="subscription-dialog-title"
      PaperProps={{
        sx: {
        },
      }}
    >
      {/* AppBar for Close Button and Title */}
      <AppBar position="relative" sx={{ backgroundColor: theme.palette.primary.main }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography
            id="subscription-dialog-title"
            variant="h6"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            Shrouded Tiers
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Dialog Content */}
      <Box
        sx={{
          p: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          overflowY: "auto", // Allows scrolling if content overflows
        }}
      >
        {/* Image */}
        <Box
          component="img"
          src="https://shrouded.io/paywall.png"
          alt="Subscription Modal"
          sx={{
            width: "100%",
            maxWidth: 400,
            maxHeight: 200,
            objectFit: "cover",
            mb: 4,
            borderRadius: 2,
          }}
        />

        {/* Plan Selection */}
        <ToggleButtonGroup
          value={selectedPlan}
          exclusive
          onChange={(_, value) => {
            if (value) setSelectedPlan(value as chargeType);
          }}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            mb: 2,
            width: "100%",
            maxWidth: 400,
          }}
        >
          {Object.keys(planDetails).map((plan) => (
            <ToggleButton
              key={plan}
              value={plan}
              sx={{
                flex: 1,
                textTransform: "capitalize",
                borderRadius: 2,
                fontSize: "1rem"
              }}
            >
              {plan.charAt(0).toUpperCase() + plan.slice(1)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>

        {/* Plan Details */}
        <Typography variant="h6" sx={{ mb: 0 }}>
          {planDetails[selectedPlan][1]}
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {planDetails[selectedPlan][0]}
        </Typography>


        {/* Subscription Button */}
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={handleSubscription}
          sx={{ width: "100%", maxWidth: 400 }}
        >
          Continue to Payment
        </LoadingButton>
      </Box>
    </Dialog>
  );
};

export default SubscriptionDialog;
