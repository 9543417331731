import axios, { AxiosInstance } from "axios";
import AuthService from "./AuthService";
import { ApiResponse } from "./ApiResponse";
import { ErrorResponse } from "../types";

class EmailService {
  private static instance: EmailService; // Singleton instance
  private api: AxiosInstance;

  private constructor() {
    this.api = axios.create({
      baseURL: `${process.env.REACT_APP_SHROUDED_BASE_URL}/v1/email`,
    });

    this.api.interceptors.request.use((config) => {
      const token = AuthService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  public static getInstance(): EmailService {
    if (!EmailService.instance) {
      EmailService.instance = new EmailService();
    }
    return EmailService.instance;
  }

  /**
   * Get all verified emails
   */
  async getAllVerifiedEmails(): Promise<ApiResponse<any[], ErrorResponse>> {
    try {
      const response = await this.api.get("/");
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Create a new email verification entry
   * @param email Email address to verify
   */
  async createEmail(email: string): Promise<ApiResponse<{ success: boolean }, ErrorResponse>> {
    try {
      const response = await this.api.post("/", { email });
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Delete an email
   * @param email Email address to delete
   */
  async deleteEmail(email: string): Promise<ApiResponse<{ success: boolean }, ErrorResponse>> {
    try {
      const response = await this.api.delete("/", { data: { email } });
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Send a verification email
   * @param email Email address to verify
   */
  async sendVerifyEmail(email: string): Promise<ApiResponse<{ success: boolean }, ErrorResponse>> {
    try {
      const response = await this.api.post("/sendVerify", { email });
      return ApiResponse.success(response.data);
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Error handler for API requests
   */
  private handleError(error: any): Error {
    if (axios.isAxiosError(error)) {
      console.error("API Error:", error.response?.data || error.message);
      return new Error(error.response?.data?.message || "An API error occurred");
    } else {
      console.error("Unexpected Error:", error);
      return new Error("An unexpected error occurred");
    }
  }
}

export default EmailService.getInstance();
