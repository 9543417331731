import React, { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Google as GoogleIcon, Apple as AppleIcon } from "@mui/icons-material";
import AuthService from "../services/AuthService";
import { useNavigate } from "react-router-dom";

const LoginScreen: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [showMfaField, setShowMfaField] = useState(false);
  const [error, setError] = useState("");
  const [resolver, setResolver] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState({
    emailPassword: false,
    google: false,
    apple: false,
    autoLogin: true,
  });

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleEmailPasswordLogin = async () => {
    setError("");
    setMessage("");
    setLoading((prev) => ({ ...prev, emailPassword: true }));

    try {
      const resp = await AuthService.loginWithEmailAndPassword(email, password);

      if ("mfaRequired" in resp && resp.mfaRequired) {
        setResolver(resp.resolver);
        setShowMfaField(true);
        setMessage("MFA required. Please enter the code sent to your device.");
      } else {
        navigate("/dashboard");
      }
    } catch (err: any) {
      setError(err.message || "Login failed. Please try again.");
    } finally {
      setLoading((prev) => ({ ...prev, emailPassword: false }));
    }
  };

  const handleVerifyMfa = async () => {
    setError("");
    setMessage("");
    setLoading((prev) => ({ ...prev, emailPassword: true }));

    try {
      await AuthService.verifyMfa(resolver, mfaCode);
      navigate("/dashboard");
    } catch (err: any) {
      setError(err.message || "Failed to verify MFA code. Please try again.");
    } finally {
      setLoading((prev) => ({ ...prev, emailPassword: false }));
    }
  };

  const handleSocialLogin = async (provider: "google" | "apple") => {
    setError("");
    setLoading((prev) => ({ ...prev, [provider]: true }));

    try {
      const token =
        provider === "google"
          ? await AuthService.loginWithGoogle()
          : await AuthService.loginWithApple();
      navigate("/dashboard");
    } catch (err: any) {
      setError(err.message || `Failed to log in with ${provider}.`);
    } finally {
      setLoading((prev) => ({ ...prev, [provider]: false }));
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="#f5f5f5">
      <Card elevation={3} style={{ width: "400px", padding: "20px" }}>
        <CardMedia
          component="img"
          image="https://shrouded.io/Shrouded-app-icon.png"
          alt="Logo"
          style={{
            height: "100px",
            objectFit: "contain",
            margin: "20px auto 0 auto",
            width: "auto",
          }}
        />
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>

          {message && (
            <Typography color="primary" align="center" gutterBottom>
              {message}
            </Typography>
          )}

          {error && (
            <Typography color="error" align="center" gutterBottom>
              {error}
            </Typography>
          )}

          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading.emailPassword}
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading.emailPassword}
          />

          {showMfaField && (
            <TextField
              label="MFA Code"
              type="text"
              fullWidth
              margin="normal"
              variant="outlined"
              value={mfaCode}
              onChange={(e) => setMfaCode(e.target.value)}
              disabled={loading.emailPassword}
            />
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: "20px", marginBottom: "20px" }}
            loading={loading.emailPassword}
            onClick={showMfaField ? handleVerifyMfa : handleEmailPasswordLogin}
            disabled={!email || !password || (showMfaField && !mfaCode) || loading.emailPassword}
          >
            {showMfaField ? "Verify MFA" : "Login"}
          </LoadingButton>

          <Typography variant="body2" align="center" gutterBottom>
            Or login with
          </Typography>

          <Box display="flex" justifyContent="center" gap="10px">
            <LoadingButton
              variant="outlined"
              startIcon={<GoogleIcon />}
              loading={loading.google}
              onClick={() => handleSocialLogin("google")}
            >
              Google
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              startIcon={<AppleIcon />}
              loading={loading.apple}
              onClick={() => handleSocialLogin("apple")}
            >
              Apple
            </LoadingButton>
          </Box>

          {/* Forgot Password and Register Links */}
          <Box mt={3} textAlign="center">
            <Link href="/forgot-password" underline="hover">
              Forgot Password?
            </Link>
            <Typography variant="body2" color="textSecondary" mt={1}>
              Don't have an account?{" "}
              <Link href="/register" underline="hover">
                Register
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginScreen;
