import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";

const drawerWidth = 240;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xl"));

  // Handle logout
  const handleLogout = async () => {
    await AuthService.logout();
    navigate("/"); // Redirect to login after logout
  };

  // Handle sidebar toggle
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Placeholder navigation handler
  const handlePlaceholderNavigation = (route: string) => {
    navigate(route);
  };

  // Sidebar content
  const drawerContent = (
    <Box
      sx={{
        width: drawerWidth,
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Divider />
      <List>
        <ListItemButton onClick={() => handlePlaceholderNavigation("/dashboard")}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={() => handlePlaceholderNavigation("/account")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemButton>
        <ListItemButton onClick={() => handlePlaceholderNavigation("/to-emails")}>
          <ListItemIcon>
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary="To Emails" />
        </ListItemButton>
      </List>
    </Box>
  );

  return (
    <>
      {/* Main AppBar */}
      <AppBar position="fixed" color="primary" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          {/* Burger Button for Mobile Sidebar */}
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              Shrouded
            </Link>
          </Typography>
          {/* Existing Buttons */}
          {AuthService.isLoggedIn() ? (
            <>
              <Button color="inherit" onClick={() => navigate("/dashboard")}>
                Shrouds
              </Button>
              <Button color="inherit" onClick={handleLogout}>
                Logout
              </Button>
            </>
          ) : (
            <Button color="inherit" onClick={() => navigate("/")}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Sidebar for authenticated users */}
      {AuthService.isLoggedIn() && (
        <Drawer
          anchor="left"
          open={!isMobile || mobileOpen}
          onClose={handleDrawerToggle}
          variant={isMobile ? "temporary" : "permanent"}
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              top: 55, // Align under AppBar
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default Navbar;
