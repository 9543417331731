import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "./screens/LoginScreen";
import Dash from "./screens/DashScreen";
import AccountScreen from "./screens/AccountScreen";
import ToEmailsScreen from "./screens/ToEmailsScreen";
import SupportScreen from "./screens/SupportScreen";
import Navbar from "./components/Navbar";
import { Box } from "@mui/material";
import AuthService from "./services/AuthService";
import CreateProxyScreen from "./screens/CreateProxyScreen";
import EmailDomainScreen from "./screens/EmailDomainScreen";
import RegisteredEmailScreen from "./screens/RegisteredEmailScreen";
import AgreeScreen from "./screens/AgreeScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // List of routes that do not require authentication
  const publicRoutes = ["/", "/auth", "/register", "/forgot-password"];

  useEffect(() => {
    AuthService.onLogin(() => {
      navigate("/dashboard");
    });
    AuthService.onLogout(() => {
      navigate("/");
    });

    const isPublicRoute = publicRoutes.includes(location.pathname);

    if (!AuthService.isLoggedIn() && !isPublicRoute) {
      navigate("/");
    }
  }, [navigate, location, publicRoutes]);

  return (
    <>
      <Navbar /> {/* Always visible */}
      <Box sx={{ mt: 10 }}>
        <Routes>
          <Route
            path="/"
            element={AuthService.isLoggedIn() ? <Navigate to="/dashboard" replace /> : <Login />}
          />
          <Route path="/auth" element={<Login />} />
          <Route
            path="/dashboard"
            element={AuthService.isLoggedIn() ? <Dash /> : <Navigate to="/" replace />}
          />
          <Route path="/account" element={<AccountScreen />} />
          <Route path="/to-emails" element={<ToEmailsScreen />} />
          <Route path="/support" element={<SupportScreen />} />
          <Route path="/add-shroud" element={<CreateProxyScreen />} />
          <Route path="/email-domain" element={<EmailDomainScreen />} />
          <Route path="/registered-email" element={<RegisteredEmailScreen />} />
          <Route path="/agree" element={<AgreeScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        </Routes>
      </Box>
    </>
  );
};

export default App;
