import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UserService from "../services/UserService";
import AuthService from "../services/AuthService";
import SubscriptionModal from "../components/SubscriptionModal";
import { User } from "../types";

const AccountScreen: React.FC = () => {
  const [username, setUsername] = useState<string | null>(null);
  const [status, setStatus] = useState<string>("Loading...");
  const [loading, setLoading] = useState<boolean>(true);
  const [passwordChangeLoading, setPasswordChangeLoading] = useState<boolean>(false);
  const [deleteAccountLoading, setDeleteAccountLoading] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [paywall, setPaywall] = useState<boolean>(false);
  const [user, setUser] = useState<User>()
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await UserService.getUser();
        const user = response.getData();
        if (user) {
          setUser(user);
          setUsername(user.username);
          setStatus(user.subscriptionStatus);
        }
      } catch (error) {
        setStatus("Error fetching data");
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, []);

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    setPasswordChangeLoading(true);
    try {
      await AuthService.updatePassword(currentPassword, newPassword);
      alert("Password updated successfully!");
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password.");
    } finally {
      setPasswordChangeLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setDeleteAccountLoading(true);
    try {
      await UserService.deleteUser();
      alert("Your account has been permanently deleted.");
    } catch (error) {
      console.error("Error deleting account:", error);
      alert("Failed to delete account.");
    } finally {
      setDeleteAccountLoading(false);
      setIsDeleteDialogOpen(false);
    }
  };
  const onPaywallClose = () => setPaywall(false);

  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", mt: 4 }}>
      {/* Account Information Section */}
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Account Information
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography variant="body1">
              <strong>Status:</strong> {status}
            </Typography>
            <Typography variant="body1">
              <strong>Username:</strong> {username}
            </Typography>
          </>
        )}
      </Box>
      <Divider />
      <Box sx={{ mb: 3 }}>
      <Typography variant="h6" gutterBottom>
          Billing
        </Typography>
        {user?.metadata && user.metadata.provider == "revenuecat" &&
        <Typography variant="body1" gutterBottom color="secondary">
          Managed by Mobile App
        </Typography>
        }

        {user?.metadata && (user.metadata.provider === "stripe" || user.metadata.provider === "coinbase") && 
          <LoadingButton
            variant="contained"
            color="info"
            onClick={() => setPaywall(true)}
          >
            Manage Subscription
          </LoadingButton>
        }
      </Box>
      <Divider />

      {/* Delete Account Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom color="error">
          Delete Account
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Warning: Deleting your account is irreversible and will permanently remove all your data.
        </Typography>
        <Button
          variant="contained"
          color="error"
          onClick={openDeleteDialog}
        >
          Delete Account
        </Button>
      </Box>

      {/* Delete Confirmation Modal */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Confirm Account Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action is irreversible and will
            permanently remove all your data. Once deleted, your account and data cannot be
            recovered.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={deleteAccountLoading}
            onClick={handleDeleteAccount}
          >
            Delete Account
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {user && 
        <SubscriptionModal user={user} isVisible={paywall} onClose={onPaywallClose} />
      }
    </Box>
  );
};

export default AccountScreen;
