import {
  signInWithPopup,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  getMultiFactorResolver,
  PhoneAuthProvider,
} from "firebase/auth";
import { auth, googleProvider, appleProvider } from "../firebase";
import CrossTabEventEmitter from "./CrossTabEventEmitter";

class AuthService {
  private static jwtKey = "auth_token";
  private static refreshTokenKey = "refresh_token";
  private static eventEmitter = CrossTabEventEmitter.getInstance();

  static async forgotPassword(email: string): Promise<void> {
    if (!email) {
      throw new Error("Email is required to reset the password.");
    }
  
    try {
      await sendPasswordResetEmail(auth, email);
      console.log(`Password reset email sent to ${email}`);
    } catch (error: any) {
      console.error("Error sending password reset email:", error.message || error);
      if (error.code === "auth/user-not-found") {
        throw new Error("No user found with this email address.");
      } else if (error.code === "auth/invalid-email") {
        throw new Error("The email address is not valid.");
      } else {
        throw new Error("Failed to send password reset email. Please try again.");
      }
    }
  }

  static async loginWithEmailAndPassword(email: string, password: string): Promise<{ token: string; } | { mfaRequired: boolean; resolver: any }> {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      this.eventEmitter.emit("login");
      return {token: await this.handleLogin(userCredential.user)};
    } catch (error: any) {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        return { mfaRequired: true, resolver };
      }
      throw error;
    }
  }

  static async verifyMfa(resolver: any, mfaCode: string): Promise<string> {
    try {
      // Use the static `PhoneAuthProvider.credential` method
      const mfaAssertion = PhoneAuthProvider.credential(resolver.hints[0].uid, mfaCode);
      const userCredential = await resolver.resolveSignIn(mfaAssertion);
  
      this.eventEmitter.emit("login");
      return await this.handleLogin(userCredential.user);
    } catch (error) {
      console.error("Error verifying MFA code:", error);
      throw new Error("Failed to verify MFA code. Please try again.");
    }
  }

  static async loginWithGoogle(): Promise<string> {
    const result = await signInWithPopup(auth, googleProvider);
    this.eventEmitter.emit("login");
    return this.handleLogin(result.user);
  }

  static async loginWithApple(): Promise<string> {
    const result = await signInWithPopup(auth, appleProvider);
    this.eventEmitter.emit("login");
    return this.handleLogin(result.user);
  }

  private static async handleLogin(user: any): Promise<string> {
    const token = await user.getIdToken();
    const refreshToken = user.refreshToken;
    this.storeTokens(token, refreshToken);
    return token;
  }

  static async refreshToken(): Promise<string | null> {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken(true);
      this.storeTokens(token, currentUser.refreshToken);
      return token;
    }
    return null;
  }

  private static storeTokens(token: string, refreshToken: string): void {
    localStorage.setItem(this.jwtKey, token);
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  static getToken(): string | null {
    return localStorage.getItem(this.jwtKey);
  }

  static async clearTokens() {
    localStorage.removeItem(this.jwtKey);
    localStorage.removeItem(this.refreshTokenKey);
  }

  static async logout(): Promise<void> {
    try {
      await auth.signOut();
      await this.clearTokens();
      this.eventEmitter.emit("logout");
      console.log("User logged out successfully.");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  }

  static async registerWithEmailAndPassword(email: string, password: string): Promise<string> {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      this.eventEmitter.emit("login");
      return this.handleLogin(userCredential.user);
    } catch (error) {
      console.error("Error registering user:", error);
      throw error;
    }
  }

  static async  gotPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent successfully.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      throw error;
    }
  }

  static isLoggedIn(): boolean {
    return !!this.getToken();
  }

  static async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("No authenticated user found.");
    }

    try {
      const credential = EmailAuthProvider.credential(user.email!, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      console.log("Password updated successfully.");
    } catch (error) {
      console.error("Error updating password:", error);
      throw error;
    }
  }

  static async autoLogin(): Promise<string | null> {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const token = await this.refreshToken();
          resolve(token);
        } else {
          resolve(null);
        }
      });
    });
  }

  static async sendPasswordlessLoginLink(email: string): Promise<void> {
    const actionCodeSettings = {
      url: `${window.location.origin}`,
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      console.log(`Passwordless login link sent to: ${email}`);
      localStorage.setItem("emailForSignIn", email);
    } catch (error: any) {
      console.error("Error sending passwordless login link:", error.message);
      throw new Error("Failed to send the login link. Please try again.");
    }
  }

  static async completePasswordlessLogin(url: string): Promise<string> {
    try {
      if (!isSignInWithEmailLink(auth, url)) {
        throw new Error("Invalid login link.");
      }
      const email = localStorage.getItem("emailForSignIn");
      if (!email) {
        throw new Error("Email not found. Please provide the email used to receive the login link.");
      }
      const userCredential = await signInWithEmailLink(auth, email, url);
      localStorage.removeItem("emailForSignIn");
      console.log("Passwordless login successful for:", email);
      this.eventEmitter.emit("login");
      return this.handleLogin(userCredential.user);
    } catch (error) {
      console.error("Error completing passwordless login:", error);
      throw error;
    }
  }

  static isSignInWithEmailLink(url: string): boolean {
    return isSignInWithEmailLink(auth, url);
  }

  static onLogin(callback: () => void): void {
    this.eventEmitter.on("login", callback);
  }

  static onLogout(callback: () => void): void {
    this.eventEmitter.on("logout", callback);
  }

  static removeAllListeners(event: "login" | "logout"): void {
    this.eventEmitter.removeAllListeners(event);
  }
}

export default AuthService;
