import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Switch,
  Button,
  CircularProgress,
  Divider,
  Alert,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { delay } from "../utils/Tools";
import { LoadingButton } from "@mui/lab";
import UserService from "../services/UserService";
import EmailProxyService from "../services/EmailProxyService";

const RegisteredEmailScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { fullEmail, defaultEmail } = location.state as {
    fullEmail: string;
    defaultEmail: string;
  };

  const [selectedEmails, setSelectedEmails] = useState<{
    [key: string]: boolean;
  }>({ [defaultEmail]: true });
  const [user, setUser] = useState<any | null>(null);
  const [registeredEmails, setRegisteredEmails] = useState<string[]>([
    defaultEmail,
  ]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchUserData = useCallback(async () => {
    try {
      const userResp = await UserService.getUser();
      const userData = userResp.getData();
      if (!userData) {
        return;
      }
      setUser(userData);
      setRegisteredEmails(
        userData.emailVerifications
          .filter((email: any) => email.isEmailVerified)
          .map((email: any) => email.email)
      );
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const toggleSwitch = (email: string) => {
    setSelectedEmails((prevState) => ({
      ...prevState,
      [email]: !prevState[email],
    }));
  };

  const handleFinish = async () => {
    const selected = Object.keys(selectedEmails).filter(
      (email) => selectedEmails[email]
    );
    if (selected.length) {
      setLoading(true);
      try {
        await EmailProxyService.addEmailProxy(fullEmail, selected);
        await delay(500);
        navigate("/dashboard");
      } catch (error) {
        console.error("Failed to add email proxy:", error);
        setErrorMessage(
          "Failed to add email proxy. Please try again or check your input."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const navigateToAddEmailScreen = () => {
    navigate("/verify-email", { state: location.state });
  };

  return (
    <Box sx={{ padding: 4 }}>
      {/* Explanation Text */}
      <Typography variant="body1" sx={{ textAlign: "center", marginBottom: 2 }}>
        Choose the email address(es) that your shroud email will forward to.
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/* Email Selection */}
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Select forward-to email(s):
        </Typography>
        <Stack spacing={2}>
          {registeredEmails.map((email, index) => (
            <FormControlLabel
              key={index}
              control={
                <Switch
                  checked={selectedEmails[email] || false}
                  onChange={() => toggleSwitch(email)}
                  color="primary"
                />
              }
              label={email}
            />
          ))}
        </Stack>
      </Box>

      {/* Add Email Button */}
      {/* <Button
        variant="outlined"
        onClick={navigateToAddEmailScreen}
        sx={{ marginY: 3 }}
        fullWidth
      >
        Add Forward-to Email
      </Button> */}

      {/* Error Message */}
      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {errorMessage}
        </Alert>
      )}

      {/* Finish Button */}
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleFinish}
          disabled={!Object.values(selectedEmails).some((value) => value) || loading}
          loading={loading}
          fullWidth
        >
          Finish
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default RegisteredEmailScreen;
