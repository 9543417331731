import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Button, CircularProgress, Divider } from "@mui/material";
import UserService from "../services/UserService";
import { LoadingButton } from "@mui/lab";

interface EmailDomainScreenProps {
    emailPrefix: string;
    availableDomains: string[];
}  

const EmailDomainScreen = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Provide the type

  const { emailPrefix, availableDomains } = (location.state || {}) as EmailDomainScreenProps;
  console.log(availableDomains);

  const [selectedDomain, setSelectedDomain] = useState<string>(availableDomains[0]);
  const [loading, setLoading] = useState(false);

  const handleNext = async () => {
    if (selectedDomain) {
      setLoading(true);
      try {
        const response = await UserService.getUser();
        const user = response.getData();
        if (!user) {
            return;
        }
        navigate("/registered-email", { state: { fullEmail: selectedDomain, defaultEmail: user.username } });
      } catch (error) {
        console.error("Error getting default email:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Title Section */}
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 2, color: "primary.main" }}>
        Choose Your Domain
      </Typography>

      {/* Explanation Section */}
      <Typography variant="body1" sx={{ textAlign: "center", marginBottom: 3, color: "text.secondary" }}>
        Your full proxy address will be a combination of the username you provided and the domain you select here.
      </Typography>
      <Divider sx={{ backgroundColor: "primary.main", marginBottom: 3 }} />

      {/* Domain Selection Section */}
      <Box>
        <Typography variant="h6" sx={{ marginBottom: 2, color: "text.primary" }}>
          Select your shroud domain:
        </Typography>
        <RadioGroup
          value={selectedDomain}
          onChange={(event) => setSelectedDomain(event.target.value)}
        >
          {availableDomains.map((domain, index) => (
            <FormControlLabel
              key={index}
              value={domain}
              control={<Radio color="primary" />}
              label={domain}
            />
          ))}
        </RadioGroup>
      </Box>

      {/* Button Section */}
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <LoadingButton
          variant="contained"
          onClick={handleNext}
          loading={loading}
          sx={{width: "100%"}}
          disabled={!selectedDomain || loading}
          startIcon={loading && <CircularProgress size={20} />}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default EmailDomainScreen;
