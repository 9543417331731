import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserService from "../services/UserService";
import EmailProxyService from "../services/EmailProxyService";
import { User } from "../types";
import { LoadingButton } from "@mui/lab";

const CreateProxyScreen: React.FC = () => {
  const [emailProxy, setEmailProxy] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  // Regular expression to validate email prefix (e.g., "text" in "text@email.com")
  const isValidEmailPrefix = (text: string) =>
    /^[a-zA-Z0-9._-]+$/.test(text);

  useEffect(() => {
    const init = async () => {
      try {
        const response = await UserService.getUser();
        setUser(response.getData());
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    init();
  }, []);

  const handleChange = (text: string) => {
    setEmailProxy(text);
    const characterLimit = user?.minProxyCharacterLimit || 0;
    setIsValid(isValidEmailPrefix(text));
    if (characterLimit > text.length) {
      setErrorMessage(`Your shroud must be at least ${characterLimit} characters.`);
    } else {
      setErrorMessage("");
    }
  };

  const handleNext = async () => {
    if (isValid) {
      setLoading(true);
      setErrorMessage(""); // Clear previous error messages
      try {
        const availableDomains = await EmailProxyService.getDomains(emailProxy);
        const domainData = availableDomains.getData();
        if (!domainData) {
            return;
        }

        if (domainData.domains.length === 0) {
          setErrorMessage("This username is not available");
        } else {
          navigate("/email-domain", {
            state: { emailPrefix: emailProxy, availableDomains: domainData.domains },
          });
        }
      } catch (error) {
        console.error("Error fetching domains:", error);
        setErrorMessage("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 1000, mx: "auto", }}>
      {user && (
        <>
          {/* Title Section */}
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: 2, color: "primary.main" }}
          >
            Let's create your first Shroud!
          </Typography>

          <Typography
            variant="body1"
            sx={{ textAlign: "center", marginBottom: 3, color: "text.secondary" }}
          >
            Choose a name for your shroud. This will be the part before the “@”
            in your new email, like “shroudname” in “shroudname@shrouded.io”.
          </Typography>

          <Divider sx={{ backgroundColor: "primary.main", marginBottom: 3 }} />

          {/* Username Input Section */}
          <Box>
            <TextField
              fullWidth
              label="Username"
              value={emailProxy}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Enter your username"
              error={!isValid || errorMessage !== ""}
              helperText={
                errorMessage ||
                (!isValid
                  ? "Only letters, numbers, dots, underscores, and hyphens are allowed."
                  : "")
              }
              sx={{ marginBottom: 2 }}
            />
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              Because you are a {user.subscriptionStatus} user, your Shroud has
              a minimum of {user.minProxyCharacterLimit} characters.
            </Typography>
          </Box>

          {/* Button Section */}
          <Box sx={{ marginTop: 4, textAlign: "center" }}>
            <LoadingButton
              variant="contained"
              sx={{width: "100%"}}
              onClick={handleNext}
              disabled={!emailProxy || !isValid || loading}
              loading={loading}
              startIcon={loading && <CircularProgress size={20} />}
            >
                Next
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CreateProxyScreen;
